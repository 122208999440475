.navbar {
  background-color: #1b1c1d;
  font-family: Circular, Verdana, sans-serif !important;
  font-size: 1.3em !important;
  padding-top: 2em;
  padding-bottom: 2em;
  margin-bottom: 0;
  align-items: center;
  display: flex;

  /* Style navigation menu links */
  a {
    color: white;
    text-decoration: none;
  }

  .navbar-toggler {
    margin: 0.5em;
  }

  .instant-button {
    background-color: transparent;
    -webkit-box-shadow: 0 0 0 2px #2ecc40 inset !important;
    box-shadow: inset 0 0 0 2px #2ecc40 !important;
    color: #2ecc40;
    background-color: transparent;
    cursor: pointer;
    display: inline-block;
    min-height: 1em;
    outline: none;
    border: none;
    vertical-align: baseline;
    padding: 0.75em 1.5em;
    text-transform: none;
    text-shadow: none;

    line-height: 1em;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: opacity 0.1s ease, background-color 0.1s ease,
      color 0.1s ease, background 0.1s ease, -webkit-box-shadow 0.1s ease;
    transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease,
      background 0.1s ease, -webkit-box-shadow 0.1s ease;
    transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease,
      box-shadow 0.1s ease, background 0.1s ease;
    transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease,
      box-shadow 0.1s ease, background 0.1s ease, -webkit-box-shadow 0.1s ease;
  }

  .instant-button:hover {
    background-color: #2ecc40;
    color: white;
  }

  .nav-text {
    line-height: 2.5em;
    padding-left: 0.25em;
    padding-right: 0.25em;
  }
  .nav-text:hover {
    background-color: #ddd;
    color: black;
  }

  .nav-item {
    margin-left: 0.75em;
    margin-right: 0.75em;
    /* Add a grey background color on mouse-over */
  }

  @media only screen and (max-width: 767px) {
    .nav-item {
      margin: 0.5em;
    }
  }

  .centertxt {
    display: table-cell;
    vertical-align: middle;
  }
}
