.Part {
  height: 10em;
}

.Part td {
  padding-top: 1.4em !important;
}

.Part .Price {
  padding-top: 2.75em !important;
}

.NonSelectable {
  user-select: none;
}

.Clickable {
  cursor: pointer;
}
