.sticky-sidebar {
  position: -webkit-sticky;
  position: sticky;
  top: 2.5rem;
  transition: 0.3s;
  border-radius: 0;
  background: linear-gradient(130deg, #ff7a18, #af002d 41.07%, #319197 76.05%);
  padding: 4px;
}
.sticky-sidebar .sticky-inner {
  padding: 7px;
  background-color: white;
}
@media only screen and (max-width: 767px) {
  .sticky-sidebar {
    margin-top: 3em;
  }
}