/** =================== MONTSERRAT =================== **/

/** Montserrat Light **/
@font-face {
  font-family: 'Montserrat';
  font-display: swap;
  font-weight: 300;
  font-style: normal;
  src: url('./Montserrat-Light.woff2') format('woff2'),
    url('./Montserrat-Light.woff') format('woff');
}

/** Montserrat Regular **/
@font-face {
  font-family: 'Montserrat';
  font-display: swap;
  font-weight: 400;
  font-style: normal;
  src: url('./Montserrat-Regular.woff2') format('woff2'),
    url('./Montserrat-Regular.woff') format('woff');
}

/** Montserrat Regular-Italic **/
@font-face {
  font-family: 'Montserrat';
  font-display: swap;
  font-weight: 400;
  font-style: italic;
  src: url('./Montserrat-Italic.woff2') format('woff2'),
    url('./Montserrat-Italic.woff') format('woff');
}

/** Montserrat Medium **/
@font-face {
  font-family: 'Montserrat';
  font-display: swap;
  font-weight: 500;
  font-style: normal;
  src: url('./Montserrat-Medium.woff2') format('woff2'),
    url('./Montserrat-Medium.woff') format('woff');
}

/** Montserrat Bold **/
@font-face {
  font-family: 'Montserrat';
  font-display: swap;
  font-weight: 700;
  font-style: normal;
  src: url('./Montserrat-Bold.woff2') format('woff2'),
    url('./Montserrat-Bold.woff') format('woff');
}
