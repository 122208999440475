.json-editor-container {
  min-height: 20em;
  font-size: 1.1em;

  .button-container {
    width: 100%;
    display: flex;
    align-items: left;
    flex-direction: column;
    margin-top: 1em;
  }

  .json-area {
    width: 100%;
    height: 40em;
    font-family: monospace;
  }
}
