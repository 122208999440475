.fancy-card {
  @mixin center($position: absolute) {
    position: $position;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
  }

  // COLORS
  //-------------------
  $white: white;
  $red: #ee0000;
  $green: #19590f;
  $grey: #7a7a7a;
  $grey-light: #e0e0e0;
  $blue: #36a2eb;
  $blue-serious: #00629e;
  $body: #252830;
  //-------------------
  // FONTS
  //-------------------------------------------------------------
  $sans-serif: 'Circular', 'Verdana', 'sans-serif';
  //-------------------------------------------------------------

  .container-fluid {
    @include center;
    width: 100%;
  }

  .media {
    background-position: center;
    background-size: cover;
    height: 100%;
    position: absolute;
    transition: all 0.3s ease;
    width: 100%;
  }

  figure {
    min-height: 370px;
    overflow: hidden;
    position: relative;
    a {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 3;
    }
    &:hover .media {
      transform: scale(1.25);
    }
  }

  figcaption {
    color: $body;
    height: #{'calc(100% - 30px)'};
    margin: 15px;
    left: 0;
    position: absolute;
    top: 0;
    width: calc(100% - 30px);
    h2 {
      font-weight: bold;
      color: black;
    }

    .body {
      background-color: white;
      bottom: 0;
      padding: 15px;
      position: absolute;
      width: 100%;
    }
  }

  @media (min-width: 767px) {
    figcaption {
      width: 250px;
    }
  }
}
